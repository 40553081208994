<template>
	<div class="scrollbarOffset">
		<div class="">
			<!-- 营养知识库 -->
			<div class="child-top">
				<div>
					<el-select @change="getData" class="last-child" clearable placeholder="请选择分类" v-model="cascaderType">
						<el-option :key="item.uuid" :label="item.name" :value="item.uuid" v-for="item in options"> </el-option>
					</el-select>
					<div class="searchArea input-from please">
						<el-input class="input-with-select please" clearable placeholder="请输入关键字" style="width: 200px" suffix-icon="iconfont iconsousuo" v-model="keyword"></el-input>
						<el-button class="searchBtn" @click="getData(1)" round>搜索</el-button>
					</div>
				</div>
				<div class="operation-button">
					<el-button @click="addDataLog" class="new_btn" round :disabled="$store.getters.permissionsStr('新增')">
						<span>新增</span>
					</el-button>
					<el-button class="del_btn" :disabled="$store.getters.permissionsStr('删除') || !sels.length" @click="someDels" round>
						<span>删除</span>
					</el-button>
				</div>
			</div>
			<!--表格-->
			<el-table
				:empty-text="tableData.length ? '' : '暂无数据'"
				:data="!$store.getters.permissionsStr('列表') ? tableData : []"
				@selection-change="selsChange"
				class="content-test-table"
				style="width: 100%"
				v-loading="tableLoading"
			>
				<el-table-column type="selection" width="45"> </el-table-column>
				<el-table-column label="序号" type="index" width="45">
					<template slot-scope="scope">{{ (form.page - 1) * 10 + scope.$index + 1 }}</template>
				</el-table-column>

				<el-table-column label="标题" prop="title" show-overflow-tooltip> </el-table-column>
				<el-table-column label="一级分类" prop="article_cate_name" show-overflow-tooltip> </el-table-column>

				<el-table-column label="发布人" prop="create_by" show-overflow-tooltip> </el-table-column>
				<el-table-column label="发布时间" prop="create_time"> </el-table-column>
				<el-table-column label="状态" prop="status" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-switch
							v-show="scope.row.uuid"
							v-model="scope.row.status"
							active-color="#BDC6CF"
							inactive-color="#13ce66"
							:active-value="0"
							:inactive-value="1"
							style="width: 35px"
							@change="changeStatus1(scope.row)"
							:disabled="$store.getters.permissionsStr('启/停用')"
						></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="140">
					<template slot-scope="scope">
						<el-button @click="edit(scope.row)" class="blueColor" size="medium" type="text" v-show="scope.row.uuid" :disabled="$store.getters.permissionsStr('编辑')">编辑 </el-button>
						<el-button style="color: #1a9f16" size="medium" type="text" @click="look(scope.row)" v-show="scope.row.uuid" :disabled="$store.getters.permissionsStr('查看')">查看</el-button>
						<el-button class="redColor" @click="delTableData(scope.row)" size="medium" type="text" v-show="scope.row.uuid" :disabled="$store.getters.permissionsStr('删除')"
							>删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination
				:current-page.sync="form.page"
				:page-size="form.page_siaze"
				:total="form.total"
				@current-change="handleCurrentChange"
				class="table-pagination"
				layout="total, jumper,  ->, prev, pager, next"
			></el-pagination>
		</div>
	</div>
</template>

<script>
let data = {
	id: '',
	account: '',
	name: '',
	sex: '',
	mobile: '',
	email: '',
	type: '',
	state: '',
	roleSearch: '',
	demo: '',
	create_time: '',
	cate_uuid: '',
	role_name: [],
}

export default {
	data() {
		return {
			form: {
				tableList: [],
				page: 1, //当前页
				page_size: 10,
				keyword: '', //搜索框
				total: 0,
				numType: '5',
			},
			sels: [], // 列表选中列
			keyword: '',
			formsearch: {
				uuid: '',
				keywords: '',
			},
			currentPage: 1,
			tableTotal: 1,
			//列表数据
			tableData: [{ uuid: 0 }],
			input4: '',
			roleData: {
				options: [],
				value: '',
				station: [],
				stationValue: '',
			},
			form: {
				...data,
			},
			inputType: '',
			tableDataSelectArr: [],
			tableLoading: true,
			options: [],
			cascaderType: '',
		}
	},
	computed: {
		// roleArr() {
		//   let arr = [];
		//   this.form.role_name.forEach((i, k) => {
		//     arr.push(i.name);
		//   });
		//   return arr.join(",");
		// },
	},
	created() {
		var obj = JSON.parse(sessionStorage.getItem('arch_search'))
		if (obj) {
			this.cascaderType = obj.cascaderType
			this.keyword = obj.keyword
			this.form.page = obj.page
		}
		this.getData()
		this.getSelectData()
	},
	mounted() {},

	methods: {
		edit(rows) {
			sessionStorage.setItem('arch_search', JSON.stringify({ cascaderType: this.cascaderType, keyword: this.keyword, page: this.form.page }))
			this.$router.push({
				path: `/nutritionSet/knowledgeList/knowledgeAddS?uuid=${rows.uuid}&&sh=show`,
			})
		},
		look(rows) {
			sessionStorage.setItem('arch_search', JSON.stringify({ cascaderType: this.cascaderType, keyword: this.keyword, page: this.form.page }))
			this.$router.push({
				path: `/nutritionSet/knowledgeList/knowledgeAddS?uuid=${rows.uuid}&&sh=show&&name=look`,
			})
		},
		//新增
		addDataLog() {
			sessionStorage.setItem('arch_search', JSON.stringify({ cascaderType: this.cascaderType, keyword: this.keyword, page: this.form.page }))

			this.$router.push(`/nutritionSet/knowledgeAdd?sh=show`)
		},
		tableRowSwichArr() {
			let arr = []
			this.tableDataSelectArr.forEach((i, k) => {
				arr.push(i.uuid)
			})
			this.tableRowSwichFunc({ uuid: arr.join(',') })
		},
		//显示与隐藏
		tableRowSwichFunc(data) {
			this.$axios.post('/p/article/able', this.$qs(data)).then(res => {
				if (res.data.code === 0) {
					this.getData()
					this.$utils.message()
				} else {
					this.$utils.message({
						type: 'error',
						message: res.message,
					})
				}
			})
		},
		//获取领域
		getSelectData() {
			this.$axios.post('p/articleCate/all', this.$qs({ type: 1 })).then(res => {
				if (res.data.code === 0) {
					this.options = res.data.data
					this.roleData.options = res.data.data
				} else {
				}
			})
		},
		// 分页
		handleCurrentChange(val) {
			this.form.page = val
			this.getData()
		},
		getData() {
			this.tableLoading = true
			let data = {
				page: this.form.page,
				page_size: this.form.page_size,
				type: 1,
				keyword: this.keyword,
			}
			if (this.cascaderType !== '') {
				data.cate_uuid = this.cascaderType
			}
			for (let i in data) {
				if (data[i] === '') {
					delete data[i]
				}
			}
			this.$axios
				.post('/p/article/lists', this.$qs(data))
				.then(res => {
					if (res.data.code === 0) {
						this.tableData = res.data.data.rows
						this.form.total = res.data.data.total
					} else {
						this.$utils.message({
							type: 'error',
							message: res.message,
						})
					}
				})
				.then(() => {
					this.tableLoading = false
				})
		},
		selsChange(sels) {
			this.sels = sels
		},
		//多选删除
		someDels() {
			let that = this
			if (that.sels.length == 0) {
				that.$message({
					message: '请选择要删除的数据',
					type: 'error',
					duration: 4000,
				})
				return
			}
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'success',
				closeOnClickModal: false,
				cancelButtonClass: '',
				center: true,
			}).then(() => {
				let params = []
				let url = '/p/article/del'
				for (let i = 0; i < this.sels.length; i++) {
					params[i] = this.sels[i].uuid
				}
				let ids = params.join(',')
				let data = { uuids: ids }
				this.$axios.post(url, this.$qs(data)).then(res => {
					if (res.data.code == 0) {
						this.getData()
						this.$message({
							message: res.data.message,
							type: 'success',
						})
					} else {
						this.$message({
							message: res.data.message,
							type: 'error',
						})
					}
				})
			})
		},
		// 单行删除
		delTableData(row) {
			this.$confirm('确定删除数据吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true,
			}).then(() => {
				this.$axios
					.post(
						'/p/article/del',
						this.$qs({
							uuids: row.uuid,
						})
					)
					.then(res => {
						if (res.data.code === 0) {
							this.$message({
								message: res.data.message,
								type: 'success',
							})
							this.getData(this.currentPage)
						} else {
							this.$utils.message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
					.catch(error => {})
			})
		},
		/*表格选中*/
		handleSelectionChange(val) {
			this.tableDataSelectArr = val
		},
		changeStatus1(data) {
			let params = {
				uuid: data.uuid,
				status: data.status,
			}
			//获取列表
			let url = 'p/article/able'
			this.$axios
				.post(url, this.$qs(params))
				.then(response => {
					if (response.data.code == 0) {
						this.$message({
							message: '切换成功',
							type: 'success',
						})
						this.getData(this.currentPage)
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
	},
}
</script>
<style lang="scss" scoped>
.please {
	margin-top: 10px;
	float: right !important;
}
.last-child {
	float: left;
	margin-top: 10px;
	// margin-left: 20px !important;
}
.detailsTitle {
	border-bottom: 1px solid #ccc;
	padding: 10px 0;
	font-size: 16px;

	& > div {
		float: left;
		margin-right: 10px;

		i {
			color: #fc940a;
			margin-right: 10px;
		}
	}

	& > div:nth-child(1) {
		margin-left: 24px;
	}
}

.details-wrap {
	margin-top: -20px;

	& > p {
		font-size: 20px;
		text-align: center;
	}

	.el-button.el-button--primary {
		margin: 14px auto;
	}
}

.detailsItem {
	padding: 20px 0 0 0;

	div {
		font-size: 16px;
		padding: 0 0 0 24px;

		span {
			display: inline-block;
		}

		span:nth-child(1) {
			width: 100px;
			color: #a0a6ab;
		}
	}
}

.ModifyDoalog {
	margin-top: -20px;
	padding: 0 0 0 20px;

	& > p {
		font-size: 20px;
		color: #3b3b3b;
		text-align: center;
		margin-bottom: 20px;
	}

	.el-form {
		padding-right: 30px;
	}
}

.dialogFormChecked {
	width: 50%;
	float: left;
	margin-right: 0;
	padding-left: 14px;
	box-sizing: border-box;

	span {
		margin-left: 4px;
	}
}
</style>

<style lang="scss" scoped>
.child-top {
	height: 73px;
	.el-select {
		border-radius: 4px 0 0 4px;

		.el-input__inner {
			border-radius: 0 4px 4px 0;
		}
	}

	.left-child-input {
		margin-left: -1px;
		float: left;
		width: 150px;

		.el-input__inner {
			border-radius: 0 4px 4px 0;
		}
	}
}

.dialog-wrap {
	& > div:nth-child(1) {
		width: 200px;
		float: left;

		h4 {
			font-size: 24px;
			color: #626262;
			padding: 0 0 10px 0;
		}

		p {
			color: #b8c4ce;
			font-size: 16px;
			line-height: 30px;
			margin: 10px 0;
		}

		button {
			border: 1px solid #2493e7;
			color: #2493e7;
		}

		.upload-wrap {
			width: 100%;
			height: 40px;
			position: relative;
			text-align: center;
			cursor: pointer;
			overflow: hidden;

			span {
				z-index: 1;
				line-height: 40px;
				color: #2493e7;
				font-size: 18px;
			}

			input {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				opacity: 0;
				z-index: 2;
			}
		}
	}

	& > div:nth-child(2) {
		width: 500px;
		float: left;
	}
}
.disColor {
	color: #ccc;
}

.text {
	display: inline-block;
	margin-left: 20px;
}

/deep/.el-dialog__title {
	color: #303133;
	font-weight: 700;
}

/deep/.el-input {
	position: relative;
	font-size: 0.72917rem;
	display: inline-block;
	width: 210px;
}

/deep/.el-table th > .cell {
	padding-left: 15px;
	width: 100px;
}
/deep/.el-pagination__editor.el-input {
	width: 50px;
}

.child-top {
	.input-from {
		position: relative;
		.searchBtn {
			position: absolute;
			top: 0px;
			left: 13rem;
			// margin-top: 10px;
		}
	}
	.operation-button {
		// float: left;
		margin-bottom: 10px;
		margin-top: 20px;
	}
}
.child-top .operation-button .del_btn:hover {
	background: #eb1e44;
	color: #ffffff;
}

.el-pagination {
	padding-bottom: 10px;
}
.el-table__row {
	width: 100%;
}

.last-child {
	padding-top: 10px;
}
</style>
